
import { Component, Vue } from "vue-property-decorator";
import RenameView from "@/components/modal/RenameView.vue";
import RemoveView from "@/components/modal/RemoveView.vue";
import LayoutTooltip from "@/components/layoutTooltips/LayoutTooltip.vue";
import LeftBar from "@/components/ats/layout/LeftBar.vue";
import TopBar from "@/components/ats/layout/TopBar.vue";

@Component({
    name: "Layout",
    components: { TopBar, LeftBar, LayoutTooltip, RemoveView, RenameView },
})
export default class Layout extends Vue {}
